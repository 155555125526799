<template>
  <div>
    <b-form-group>
      <label>
        <strong class="h6">Content text</strong>
      </label>
      <b-form-textarea
        rows="3"
        v-model="sentenceData.content"
      />
    </b-form-group>
    <strong class="h6">Subtitle</strong>
    <ui-component :component-data="sentenceData.subtitle" class="border" />
    <b-row class="mt-1">
      <b-col cols="8">
        <strong class="h6">Sample Audio</strong>
        <ui-component :component-data="sentenceData.sample.audio" class="border" />
      </b-col>
      <!-- <b-col cols="4">
        <strong class="h6">Content Audio (Low speed)</strong>
        <ui-component :component-data="sentenceData.sample.audio_high_speed" class="border" />
      </b-col> -->
      <b-col cols="4">
        <strong class="h6">Speaking Duration</strong>
        <b-input-group append="seconds">
          <b-form-input type="number" v-model="sentenceData.duration" name="sentence_duration" />
        </b-input-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    sentenceData: { type: Object, required: true },
  },
}
</script>
