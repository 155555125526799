<template>
  <div>
    <b-button variant="outline-primary" pill @click="create">
      <span class="align-middle">{{ title }}</span>
      <feather-icon
        icon="PlusIcon"
        class="ml-50"
      />
    </b-button>
  </div>
</template>

<script>

export default {
  props: {
    title: { type: String, default: 'Create Sentence' },
  },
  methods: {
    create() {
      let new_sentence = {
        id: Date.now(),
        content: '',
        sub: '',
        duration: 20,
        sample: {
          user: {
            avatar: {
              type: 'image',
              value: null,
              meta: {
                url: null,
                alt: null,
              },
            },
            name: '',
          },
          audio: {
            type: 'audio',
            value: null,
            meta: {
              title: null,
            },
          },
        },
      }
      this.$emit('created', new_sentence)
    },
  },
}
</script>
