<template>
<div>
  <template v-if="document && category" class="pt-3">
    <page-breadcrumb :title="`Lesson Detail`" :items="breadcrumbItems" />

    <section class="my-3">
      <h2 class="h2">Lesson Overview</h2>
      <div class="mb-1 text-right">
        <strong class="h6">Level: {{ document.level | level }}</strong>
      </div>
      <b-card class="mt-1">
        <b-row>
          <b-col cols="3">
            <strong class="h6">Thumbnail</strong>
            <ui-component :component-data="document.thumbnail" class="border" />
          </b-col>
          <b-col cols="9">
            <strong class="h6">Title</strong>
            <b-form-input class="mb-3" type="text" v-model="document.title" placeholder="Enter your title here" />
          </b-col>
        </b-row>
      </b-card>
    </section>

    <section class="my-3">
      <h2 class="h2">Sentences</h2>
      <div class="mt-1">
        <app-collapse type="shadow" class="mt-2 border p-1">
          <draggable v-model="document.sentences" class="list-group list-group-flush" tag="ul"
            handle=".sentence-grab-button"
          >
            <transition-group type="transition" name="list">
              <app-collapse-item v-for="(sentence, sentenceIndex) in document.sentences" :key="sentence._id || sentence.id"
                class="question-item"
                :title="`Sentence ${sentenceIndex + 1}`" :is-visible="sentenceIndex === document.sentences.length - 1"
              >
                <template slot="header">
                  <div class="d-flex justify-content-between align-items-center flex-grow-1 w-100">
                    <div class="d-flex align-items-center w-100 sentence-grab-button" style="cursor: grab;">
                      <feather-icon icon="TargetIcon" />
                      <span class="lead collapse-title ml-50 text-truncate d-block w-100" style="max-width: 90%">{{ sentence.content || `Sentence id: ${sentence.id}` }}</span>
                    </div>
                    <div style="position: absolute; right: 30px; ">
                      <b-button variant="flat-danger" class="btn-icon rounded-circle"
                        @click.stop="removeSentence(sentenceIndex)"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </div>
                  </div>
                </template>
                <sentence-card :sentence-data="sentence" />
              </app-collapse-item>
            </transition-group>
          </draggable>

          <div v-if="!document.sentences.length" class="text-center"><span>No sentence, create them!</span></div>
        </app-collapse>
        <create-sentence-form class="text-center mt-1"
          @created="addSentence"
        />
      </div>
    </section>
    <section class="mb-3">
      <div class="d-flex">
        <b-button class="ml-auto" @click="updateBot">
          <b-spinner v-if="isUpdatingBot" small />
          <span v-else>Update challenge bot</span>
        </b-button>
      </div>
    </section>
    <action-buttons update-button back-button delete-button
      @update="update" :is-updating="isUpdating" @delete="deleteDocument" :is-deleting="isDeleting"
    />
  </template>
</div>
</template>
<script>
import service from '../service'
import draggable from 'vuedraggable'
import CreateSentenceForm from './_components/CreateSentenceForm.vue'
import SentenceCard from './_components/SentenceCard.vue'

export default {
  components: {
    draggable,
    CreateSentenceForm,
    SentenceCard,
  },
  filters: {
    level(numb) {
      let levels = {
        1: 'Easy',
        2: 'Normal',
        3: 'Hard',
      }
      return levels[numb] || 'NaN'
    }
  },
  data() {
    return {
      document: null,
      category: null,
      isUpdating: false,
      isDeleting: false,
      isUpdatingBot: false,
    }
  },
  computed: {
    documentId() {
      return this.$route.params.lesson_id
    },
    breadcrumbItems() {
      let items = [
        {
          text: 'Category List',
          to: { name: 'arena-category-list' },
        },
        {
          text: `${this.category.title}`,
          to: { name: 'arena-category-detail', params: { category_id: this.category._id } },
        },
        {
          text: 'Lessons',
          to: { name: 'arena-category-detail', params: { category_id: this.category._id } },
        },
        { text: `${this.document.title || this.document._id}`, active: true },
      ]
      return items
    },
  },
  created() {
    this.getDocument()
  },
  methods: {
    async getDocument() {
      let data = await service.get({ id: this.documentId })
      if(!data) {
        return ''
      }
      this.category = data.category
      this.document = data.document
    },
    async update() {
      this.isUpdating = true
      const response = await service.update({ data: JSON.stringify(this.document) })
      if (response.data.type === 'DATA') {
        this.$store.dispatch('pushSuccessNotify', { text: 'Successfully updated lesson!' })
      }
      this.getDocument();
      this.isUpdating = false
    },
    async deleteDocument() {
      this.isDeleting = true
      const response = await service.delete({ id: this.documentId })
      if (response.data.type === 'DATA') {
        this.$store.dispatch('pushSuccessNotify', { text: 'Successfully deleted lesson!' })
      }
      this.isDeleting = false
      this.$router.push({ name: 'arena-category-detail', params: { category_id: this.category._id } })
    },
    async updateBot() {
      this.isUpdatingBot = true
      const response = await service.updateBot({ id: this.documentId })
      if (response.data.type === 'DATA') {
        this.$store.dispatch('pushSuccessNotify', { text: 'Successfully update sample record for lesson!' })
      }
      this.isUpdatingBot = false
    },
    async addSentence(sentence) {
      this.document.sentences.push(sentence)
    },
    async removeSentence(sentenceIndex) {
      this.document.sentences.splice(sentenceIndex, 1)
    },
  },
}
</script>

<style scoped>
.question-item {
  transition: ease 0.6s
}
</style>
